import { ActionWithCountdown } from '@swe/shared/ui-kit/components/action-with-countdown';
import Form from '@swe/shared/ui-kit/components/form';
import { Hero } from '@swe/shared/ui-kit/components/form-hero';
import Grid from '@swe/shared/ui-kit/components/grid';
import { PhoneCodeIllustration } from '@swe/shared/ui-kit/components/illustration';
import Stack from '@swe/shared/ui-kit/components/stack';

import { ReactNode } from 'react';

import { CONFIRM_PHONE_FORM_NAME, initialValues, validationSchema } from './config';

import { CodeVerificationFormValues } from './interface';

type CodeVerificationFormProps = {
  title?: ReactNode;
  description?: ReactNode | ((phoneNumber: string) => ReactNode);
  phoneNumber: string;

  onSubmit: (form: CodeVerificationFormValues) => void;
  onCancel: () => void;
  onResendCode: () => Promise<number> | number;
};

const CodeVerificationForm = ({
  title = 'Security Code',
  description = (phoneNumber) => (
    <div>
      <div>Code sent to {phoneNumber}.</div>
      <div>It will be active for 3 min.</div>
    </div>
  ),
  phoneNumber,
  onSubmit,
  onCancel,
  onResendCode,
}: CodeVerificationFormProps) => {
  return (
    <Hero
      size="lg"
      illustration={<PhoneCodeIllustration />}
      title={title}
      description={description instanceof Function ? description(phoneNumber) : description}
    >
      <Form.Builder
        name="codeVerificationForm"
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Stack spacing="md">
          <div>
            <Form.Input
              name="code"
              label="Code from SMS"
              placeholder="Enter code"
              maskPreset="code_4digit"
              autocomplete="one-time-code"
              isClearable
            />
            <ActionWithCountdown
              name={CONFIRM_PHONE_FORM_NAME}
              onClick={onResendCode}
            >
              Resend code
            </ActionWithCountdown>
          </div>
          <Grid.Row>
            <Grid.Cell cols={6}>
              <Form.Button
                block
                color="light"
                onClick={onCancel}
              >
                Cancel
              </Form.Button>
            </Grid.Cell>
            <Grid.Cell cols={6}>
              <Form.SubmitButton block>Confirm</Form.SubmitButton>
            </Grid.Cell>
          </Grid.Row>
        </Stack>
      </Form.Builder>
    </Hero>
  );
};

export * from './interface';
export type { CodeVerificationFormProps };
export { CodeVerificationForm };
