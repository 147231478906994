import { yup } from '@swe/shared/ui-kit/components/form';

import { CodeVerificationFormValues } from './interface';

const validationSchema = yup.object({
  code: yup.string().min(4, 'Code must consist of 4 digits').max(4),
});
const initialValues: CodeVerificationFormValues = { code: '' };

const CONFIRM_PHONE_FORM_NAME = 'codeVerificationForm';

export { validationSchema, initialValues, CONFIRM_PHONE_FORM_NAME };
