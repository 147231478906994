import { useToggleable } from '@swe/shared/hooks/use-toggleable';
import Modal from '@swe/shared/ui-kit/components/modal';
import { formatDate } from '@swe/shared/utils/date';

import { useCallback, useMemo, useState } from 'react';

import { CodeVerificationForm, CodeVerificationFormValues } from 'common/components/code-verification-form';
import { considerRequestTimer } from 'common/helpers/consider-request-timer';
import { useCurrentUser } from 'common/providers/user';
import { useLoyaltySettings } from 'common/use-cases/use-loyalty-settings';
import { SuspendSelectorModal } from 'domains/profile/containers/notifications/components/suspend-selector-modal';
import { NotificationSetting, TriggeredNotification } from 'domains/profile/containers/notifications/types';
import { useNotifications } from 'domains/profile/use-cases/use-notifications';
import ContactsConfirmPhoneNumberEndpoint from 'endpoints/profile/contacts/confirm-phone';
import ContactsVerifyPhoneNumberEndpoint from 'endpoints/profile/contacts/verify-phone';
import { matchMethod, Notification, NotificationMethod, NotificationType } from 'entities/profile/notifications';

const mapNotificationToSetting = (
  type: NotificationType,
  notification: Notification,
  onToggle: (type: NotificationType, notification: Notification) => void,
): NotificationSetting => ({
  id: notification.notificationMethod,
  label: notification.name,
  extra: notification.suspendUntilDate ? `Disabled until ${formatDate(notification.suspendUntilDate, 'P')}` : '',
  isActive: notification.enabled,
  onToggle: () => {
    onToggle(type, notification);
  },
});
const mapNotificationsToSettings = (
  type: NotificationType,
  notifications: Notification[],
  onToggle: (type: NotificationType, notification: Notification) => void,
) => notifications.map((notification) => mapNotificationToSetting(type, notification, onToggle));

const useNotificationsSettings = () => {
  const { user } = useCurrentUser();
  const isPhoneVerified = user?.isCustomerPhoneVerified ?? true;

  const { settings: loyaltySettings } = useLoyaltySettings();
  const { transactional = [], marketing = [], isLoading, isValidating, toggleNotification } = useNotifications();
  const [triggeredNotification, trigger] = useState<TriggeredNotification | undefined>();

  const [isOpened, openSuspendSelectorModal, closeSuspendSelectorModal] = useToggleable();
  const [isPhoneVerificationOpened, openPhoneVerification, closePhoneVerification] = useToggleable();

  const isOneNotificationLeft = useMemo(() => {
    if (
      !loyaltySettings?.isDistributionSubscriptionRequired ||
      triggeredNotification?.type !== NotificationType.Marketing
    )
      return false;

    return marketing.reduce((activeCount, n) => (n.enabled ? activeCount + 1 : activeCount), 0) === 1;
  }, [marketing, loyaltySettings?.isDistributionSubscriptionRequired, triggeredNotification?.type]);

  const verifyPhoneNumber = useCallback(
    () => considerRequestTimer(ContactsVerifyPhoneNumberEndpoint.request, { params: undefined }),
    [],
  );

  const handleToggle = useCallback(
    async (type: NotificationType, notification: Notification) => {
      trigger({
        type,
        method: notification.notificationMethod,
        notification,
      });

      if (!isPhoneVerified && matchMethod(notification, NotificationMethod.Sms) && notification.enabled === false) {
        await verifyPhoneNumber();
        openPhoneVerification();
        return;
      }

      if (!notification.enabled) {
        return toggleNotification(type, notification.notificationMethod, notification);
      }

      openSuspendSelectorModal();
    },
    [isPhoneVerified, openPhoneVerification, openSuspendSelectorModal, toggleNotification, verifyPhoneNumber],
  );
  const handleSuspendSelected = useCallback(
    async (type: NotificationType, notification: Notification, suspendOn?: number) => {
      closeSuspendSelectorModal();
      await toggleNotification(type, notification.notificationMethod, notification, suspendOn);
    },
    [closeSuspendSelectorModal, toggleNotification],
  );
  const handlePhoneVerified = useCallback(
    async ({ code }: CodeVerificationFormValues) => {
      try {
        await ContactsConfirmPhoneNumberEndpoint.request({
          code,
        });

        closePhoneVerification();

        if (!triggeredNotification) {
          return;
        }

        return toggleNotification(
          triggeredNotification.type,
          triggeredNotification.method,
          triggeredNotification.notification,
        );
      } catch (e) {
        console.error(e);
      }
    },
    [closePhoneVerification, toggleNotification, triggeredNotification],
  );

  const settings = useMemo(
    () => ({
      marketing: mapNotificationsToSettings(NotificationType.Marketing, marketing, handleToggle),
      transactional: mapNotificationsToSettings(NotificationType.Transactional, transactional, handleToggle),
    }),
    [handleToggle, marketing, transactional],
  );

  return {
    settings,
    isLoading,
    isValidating,
    SuspendModal: (
      <SuspendSelectorModal
        isOpened={isOpened}
        notificationType={triggeredNotification?.type}
        notification={triggeredNotification?.notification}
        isOneNotificationLeft={isOneNotificationLeft}
        onSuspend={handleSuspendSelected}
        onClose={closeSuspendSelectorModal}
      />
    ),
    PhoneVerificationModal: user ? (
      <Modal
        visible={isPhoneVerificationOpened}
        onClose={closePhoneVerification}
        ariaLabel="Phone Verification Modal"
      >
        <CodeVerificationForm
          title="Verify your phone number"
          description={(phoneNumber) => `We sent a code to ${phoneNumber}. Please enter it below.`}
          phoneNumber={user.phoneNumber!}
          onSubmit={handlePhoneVerified}
          onCancel={closePhoneVerification}
          onResendCode={verifyPhoneNumber}
        />
      </Modal>
    ) : null,
  };
};

export type { NotificationSetting };
export { useNotificationsSettings };
