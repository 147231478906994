import { useCallback } from 'react';

import { useCurrentUser } from 'common/providers/user';
import GetProfileNotificationsEndpoint from 'endpoints/profile/notifications/get-notifications';
import { ToggleMarketingNotificationEndpoint } from 'endpoints/profile/notifications/toggle-marketing-notifications';
import ToggleTransactionNotifications from 'endpoints/profile/notifications/toggle-transaction-notifications';
import { Notification, NotificationMethod, NotificationType } from 'entities/profile/notifications';

const toggleByMatch = (n: Notification, method: NotificationMethod): Notification => {
  if (n.notificationMethod === method) {
    return {
      ...n,
      enabled: !n.enabled,
      suspendUntilDate: undefined,
    };
  }

  return n;
};

const useNotifications = () => {
  const { data: notifications, mutate, isLoading, isValidating, error } = GetProfileNotificationsEndpoint.useRequest();
  const { revalidate } = useCurrentUser();

  const toggleNotification = useCallback(
    async (type: NotificationType, method: NotificationMethod, notification: Notification, suspendOn?: number) => {
      try {
        const toggle =
          type === NotificationType.Transactional
            ? ToggleTransactionNotifications.request
            : ToggleMarketingNotificationEndpoint.request;

        /**
         * While our toggle does not have any state for async toggle handlers it will look a bit laggy.
         * In that case we need to make an optimistic update first and then make request and revalidation
         */
        await mutate((data) => {
          if (!data) return data;

          const _data = { ...data };
          const key = type === NotificationType.Transactional ? ('transactions' as const) : ('promotions' as const);
          _data[key] = data[key].map((n) => toggleByMatch(n, method));

          return _data;
        }, false);
        await toggle({
          notificationMethod: method,
          enabled: !notification.enabled,
          suspendDays: notification.enabled ? suspendOn : undefined,
        });
        await revalidate();
      } catch (e) {
        console.error(e);
      } finally {
        await mutate();
      }
    },
    [mutate, revalidate],
  );

  return {
    transactional: notifications?.transactions,
    marketing: notifications?.promotions,
    revalidate: mutate,
    toggleNotification,
    isLoading,
    isValidating,
    error,
  };
};

export { useNotifications };
