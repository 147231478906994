import { Trans } from '@swe/shared/tools/intl/components/trans';
import Button from '@swe/shared/ui-kit/components/button';
import Loader from '@swe/shared/ui-kit/components/loader';
import Modal from '@swe/shared/ui-kit/components/modal';
import Stack from '@swe/shared/ui-kit/components/stack';
import Text from '@swe/shared/ui-kit/components/text';

import styles from './styles.module.scss';

import { Notification, NotificationType } from 'entities/profile/notifications';

type SuspendSelectorModalProps = {
  isOpened: boolean;
  notificationType?: NotificationType;
  notification?: Notification;
  isOneNotificationLeft?: boolean;
  onSuspend: (type: NotificationType, notification: Notification, suspendOn?: number) => void;
  onClose: () => void;
};

const SuspendSelectorModal = ({
  isOpened,
  notification,
  notificationType,
  isOneNotificationLeft: isOneLeft,
  onSuspend,
  onClose,
}: SuspendSelectorModalProps) => {
  return (
    <Modal
      visible={isOpened}
      showCloseButton
      onClose={onClose}
      ariaLabel="Suspend notifications"
    >
      {notificationType && notification ? (
        <Stack spacing="sm">
          <Text
            className={styles.heading}
            variant="headline"
            size="md"
          >
            {isOneLeft ? (
              'Hold on!'
            ) : (
              <span>
                {notificationType} {notification.name.toUpperCase()}
              </span>
            )}
          </Text>
          <Text
            className={styles.body}
            variant="body"
            size="lg"
          >
            {notificationType === NotificationType.Transactional && (
              <Trans
                id="domains.profile.notifications.suspend.transactional.warningText"
                defaultMessage="Suspend notifications about status updates and delivery updates about orders placed online."
              />
            )}
            {notificationType === NotificationType.Marketing &&
              (isOneLeft ? (
                <Trans
                  id="domains.profile.notifications.suspend.marketing.oneLeftWarningText"
                  defaultMessage="You must have at least one marketing notification option enabled to participate in the loyalty program. Disabling all marketing notifications will remove you from the loyalty program, you will no longer be able to earn or use your points. Are you sure you want to disable all marketing notifications?"
                />
              ) : (
                <Trans
                  id="domains.profile.notifications.suspend.marketing.warningText"
                  defaultMessage="Suspend notifications about discounts, store information and other promotional messages."
                />
              ))}
          </Text>
          <Button
            color="light"
            onClick={() => onSuspend(notificationType, notification, 7)}
            name="week"
          >
            Suspend for a week
          </Button>
          <Button
            color="light"
            onClick={() => onSuspend(notificationType, notification, 31)}
            name="month"
          >
            Suspend for a month
          </Button>
          <Button
            color="light"
            onClick={() => onSuspend(notificationType, notification)}
            name="disable"
          >
            Disable
          </Button>
        </Stack>
      ) : (
        <Loader
          centered
          size="lg"
        />
      )}
    </Modal>
  );
};

export type { SuspendSelectorModalProps };
export { SuspendSelectorModal };
