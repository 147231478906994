import Loader from '@swe/shared/ui-kit/components/loader';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';

import Stack from '@swe/shared/ui-kit/components/stack';

import styles from './styles.module.scss';

import { Settings } from 'domains/profile/components/settings';
import { NotificationSetting, useNotificationsSettings } from 'domains/profile/containers/notifications/hook';

type SettingsSectionProps = {
  heading: string;
  description?: string;
  settings: NotificationSetting[];
};

const SettingsSection = ({ heading, description, settings }: SettingsSectionProps) => (
  <div>
    <SectionHeading
      className={styles.section_noPaddingTop}
      subHeading={description}
      size="md"
    >
      {heading}
    </SectionHeading>
    <Settings
      name={heading}
      entries={settings}
    />
  </div>
);

const ProfileNotifications = () => {
  const { settings, isLoading, SuspendModal, PhoneVerificationModal } = useNotificationsSettings();

  if (isLoading) {
    return (
      <Loader
        size="lg"
        centered
      />
    );
  }

  return (
    <div>
      <Stack spacing="xl">
        <SettingsSection
          heading="Transactional"
          description="Notify me about orders and account updates. We recommend you keep these on so you don’t miss important information."
          settings={settings.transactional}
        />
        <SettingsSection
          heading="Marketing"
          description="Notify me about deals, personalized recommendations, events, and exclusive promotions. We recommend you keep these on so you don’t miss out on deals!"
          settings={settings.marketing}
        />
      </Stack>
      {SuspendModal}
      {PhoneVerificationModal}
    </div>
  );
};

export { ProfileNotifications };
