import Toggle from '@swe/shared/ui-kit/components/form/toggle';
import Stack from '@swe/shared/ui-kit/components/stack';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type Setting<Id> = {
  id: Id;
  label: string;
  extra?: string;
  isActive: boolean;
  onToggle: (isActive: boolean) => Promise<any> | any;
};

type SettingsProps<Id extends EntityID = EntityID> = ComponentHasClassName & {
  name: string;
  entries: Setting<Id>[];
};

const Settings = <Id extends EntityID = EntityID>({ name, className, entries }: SettingsProps<Id>) => {
  return (
    <Stack
      className={className}
      divider
    >
      {entries.map((entry) => (
        <div
          key={`${name}_${entry.id}`}
          className={styles.setting}
        >
          <div className={styles.settingBody}>
            <div className={styles.settingLabel}>{entry.label}</div>
            {entry.extra && <div className={styles.settingExtra}>{entry.extra}</div>}
          </div>
          <Toggle
            name={`${name}_${entry.id}`}
            value={entry.isActive}
            onChange={entry.onToggle}
          />
        </div>
      ))}
    </Stack>
  );
};

export type { Setting, SettingsProps };
export { Settings };
