import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { Routes } from 'common/router/constants';
import { ProfileNotifications } from 'domains/profile/containers/notifications';

const TITLE = 'Notifications';

const ProfileNotificationsPage: ApplicationPage = () => {
  return (
    <Page
      {...DEFAULT_AUTHORIZED_PAGE_PROPS}
      header={{ title: TITLE, defaultBackRoute: Routes.Profile }}
    >
      <ProfileNotifications />
    </Page>
  );
};

ProfileNotificationsPage.getMeta = () => ({ title: TITLE });

ProfileNotificationsPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default ProfileNotificationsPage;
