import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

type Params = undefined;
type Data = {
  success: boolean;
  code: string;
  nextTryInSeconds: number;
};

const NAME = '/Account/VerifyPhoneNumber';
const ContactsVerifyPhoneNumberEndpoint = createModernEndpoint<Params, Data>(NAME);

export default ContactsVerifyPhoneNumberEndpoint;
export { ContactsVerifyPhoneNumberEndpoint };
